<template>
  <div class="box">
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="100px"
      class="login-container"
    >
      <h3 class="login-title">系统登录</h3>
      <el-form-item
        label="用户名"
        label-width="80px"
        prop="username"
        class="username"
      >
        <el-input
          type="input"
          v-model="form.username"
          autocomplete="off"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="密码"
        label-width="80px"
        prop="password"
        class="password"
      >
        <el-input
          type="password"
          v-model="form.password"
          autocomplete="off"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item class="login-submit">
        <el-button type="primary" @click="login" class="login-submit"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// import Mock from 'mockjs'
import router from "@/router"; //路由
import Router from "vue-router";
import { login, getPermissionsList } from "../../api/data";
export default {
  name: "Login",
  data() {
    return {
      form: {},
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            message: "用户名长度不能小于三位",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    createObj(data, arr, buttonObj, parentCode, routerList) {
      if (Object.keys(data).length > 0) {
        var i = 0;
        for (let item in data) {
          // 如果是菜单&&状态已启用
          if (data[item].type == 1 && data[item].status == 1) {
            let obj = {
              code: data[item].permissions_code,
              is_hidden: data[item].is_hidden,
              label: data[item].permissions_name,
              is_hidden: data[item].is_hidden,
              sort: data[item].sort,
              name: data[item].permissions_code,
              path: "/" + data[item].interface_path,
              status: data[item].status,
              icon: data[item].icon,
              type: data[item].type,
              children: [],
            };
            arr && arr.push(obj);
            if (data[item].interface_path != "/") {
              routerList && routerList.push(obj);
            }

            // 如果是按钮&&状态已启用
          } else if (data[item].type == 2 && data[item].status == 1) {
            buttonObj[parentCode] &&
              buttonObj[parentCode].push(data[item].permissions_code);
          }

          if (Object.keys(data[item].children).length > 0) {
            let name = data[item].permissions_code;
            buttonObj[name] = [];
            this.createObj(
              data[item].children,
              arr[i].children,
              buttonObj,
              data[item].permissions_code,
              routerList
            );
          }
          i++;
        }
        return [arr, buttonObj, routerList];
      }
    },
    login() {
      login(this.form).then(({ data: res }) => {
        if (res.code === 200) {
          this.$message.success("登录成功");
          localStorage.setItem("userData", JSON.stringify(res.data));
          this.$store.commit("setToken", res.data.token);
          // if (res.data.role_code == 'laser') {
          //   this.$router.push({ name: "LaserCodeManag" });
          // } else {
          //   this.$router.push({ name: "home" });
          // }
          // getRouter()
          getPermissionsList().then(({ data: res }) => {
            if (res.code === 200) {
              let obj = this.createObj(res.data, [], {}, null, []);
              let menu = obj[0] || [];
              menu.sort((a, b) => {
                return b.sort - a.sort;
              });
              let routerList = obj[2];

              localStorage.setItem("routerList", JSON.stringify(routerList));
              localStorage.setItem("menu", JSON.stringify(menu));
              localStorage.setItem("button_list", JSON.stringify(obj[1]));
              let lists = [
                {
                  path: "/Index",
                  name: "Index",
                  label: "首页",
                  icon: "s-home",
                  url: "Index/index",
                },
              ];
              localStorage.setItem("tabs", JSON.stringify(lists));

              routerList = routerList.map((item, index) => {
                if (item.children && item.children.length > 0) {
                  item.children = item.children.map((it, ind) => {
                    if (it.path != "//") {
                      it.component = () =>
                        import("@/views" + it.path + "/index");
                    }
                    return it;
                  });
                }
                if (item.path != "//") {
                  item.component = () =>
                    import("@/views" + item.path + "/index");
                }
                return item;
              });
              let arr = [
                {
                  path: "/",
                  name: "Main",
                  component: () => import("@/views/Main.vue"),
                  redirect: "/login",
                  children: routerList,
                },
              ];
              // 替换现有router的routes
              router.matcher = new Router({
                mode: 'history', //浏览器工作模式
                routes: [
                  {
                    path: "/login",
                    name: "login",
                    component: () => import("@/views/Login/Login.vue"),
                  },
                  {
                    path: "/H5GotoApp",
                    name: "H5GotoApp",
                    component: () => import("@/views/H5GotoApp/H5GotoApp.vue"),
                  },
                  {
                    path: "/DoctorAI",
                    name: "DoctorAI",
                    component: () => import("@/views/DoctorAI/DoctorAI.vue"),
                  },
                  {
                    path: "/redirect/:path(.*)",
                    component: () => import("@/views/redirect/index.vue"),
                  },
                  {
                    path: "*",
                    name: "404",
                    component: () => import("@/views/redirect/404.vue"),
                  },
                ],
              }).matcher;
              router.addRoutes(arr);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
      // const token = Mock.random.guid();
      // this.$store.commit("setToken", token);
      // this.$touter.push({ name: "home" });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #2d3a4b;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
.login-title {
  margin: 0 auto 40px auto;
  text-align: center;
  color: #505458;
}
.login-submit {
  margin: 10px auto 0 auto;
}
</style>